import { MeetingLinkActionButtons } from 'react-chat-elements'
import React, { useEffect, useState } from 'react'
import { CitationButton, Wrapper } from './styled'

import 'react-chat-elements/dist/main.css'
import { Descriptions, Modal, Spin } from 'antd'
import { Message } from 'models/message'
import { capitalize } from 'services/utils'
import FileDrawer from '../FileDrawer/FileDrawer'
import CustomMessageBox from './CustomMessageBox'
import { translateMessage } from './TranslateCheckBox'
import { IoGlobe } from 'react-icons/io5'

interface IProps {
  messageList: Message[]
  isLoading?: boolean
}

const MessageList: React.FC<IProps> = (props) => {
  const { messageList, isLoading } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<string>('')
  const [items, setItems] =
    useState<{ key: string; label: string; children: any }[]>()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [urlFile, setUrlFile] = useState('')
  const [urlTitle, setUrlTile] = useState('')
  const [initialPage, setInitialPage] = useState<number | string>(1)
  const [stateTrigger, setStateTrigger] = useState<string>('')

  type LoadingStates = {
    [key: string]: boolean
  }
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({})

  const notAllowedKeys = [
    'oss_url',
    'coordinates',
    'source',
    'text_as_html',
    'file_directory',
    'last_modified',
    'file_type',
    'parent_id',
    'page_number',
    'category'
  ]

  // Rerender to update time
  const [_, setMinuteTick] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Here we update the minuteTick state to a new value which will trigger a re-render
      setMinuteTick((tick) => tick + 1)
    }, 10000) // rerender every 10 seconds

    return () => {
      // Don't forget to clear the interval to prevent memory leaks
      clearInterval(intervalId)
    }
  }, []) // An empty dependency array ensures the effect runs only once on mount

  const messageEndRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messageList])
  const onShowDrawer = (url: string, title: string, page: number | string) => {
    setUrlFile(url)
    setUrlTile(title)
    setInitialPage(page)
    setOpenDrawer(true)
  }

  const showModal = (content: string, metadata: any) => {
    setIsModalOpen(true)
    setModalContent(content)
    let itemsMetadata = []
    for (const key in metadata) {
      if (notAllowedKeys.includes(key)) continue

      const extension = metadata.hasOwnProperty('filename')
        ? metadata['filename'].split('.').pop()
        : ''

      if (key === 'page' && extension === 'docx') continue

      if (
        key === 'page' &&
        (extension === 'png' || extension === 'jpg' || extension === 'jpeg')
      )
        continue

      let children = metadata[key]

      // check if metadata[key] is json date string, format it
      const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/
      if (regex.test(metadata[key])) {
        children = new Date(metadata[key]).toLocaleString()
      }

      if (key === 'filename' && metadata.hasOwnProperty('oss_url')) {
        const page = metadata.hasOwnProperty('page') ? metadata['page'] : 1
        children = (
          <a
            onClick={() => {
              onShowDrawer(metadata['oss_url'], metadata[key], page)
            }}
          >
            {metadata[key]}
          </a>
        )
      }

      itemsMetadata.push({
        key: key,
        label: capitalize(key),
        children: children,
        span: 3
      })
    }
    setItems(itemsMetadata)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const setLoadingForButton = (buttonId: string, isLoading: boolean) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [buttonId]: isLoading
    }))
  }

  const buildMessageBox = (message: Message, index: number) => {
    const getCitationNumbers = () => {
      let references = message.type.text.match(/\[\d\]/g) as string[]
      references = references?.filter(
        (value, index) => references.indexOf(value) === index
      )
      let meetingLinks: MeetingLinkActionButtons[] = []

      if (references) {
        for (let index of references
          .map((ref) => parseInt(ref.replace('[', '').replace(']', '')))
          .sort((a, b) => a - b)) {
          meetingLinks.push({
            onClickButton: () => {
              showModal(
                message.sourcesDocuments[index - 1]?.page_content,
                message.sourcesDocuments[index - 1]?.metadata
              )
            },
            Component: () => <CitationButton>[{index}]</CitationButton>
          })
        }
      }
      return meetingLinks
    }

    return (
      <>
        {/* eslint-disable-next-line react/jsx-no-undef */}

        <CustomMessageBox
          id={message.type.id}
          loading={message.status === 'waiting'}
          position={message.type.position}
          title={message.type.title}
          type='meetingLink'
          text={message.type.text}
          date={message.type.date}
          focus={false}
          avatar={message.type.avatar}
          forwarded={false}
          notch={true}
          copyable={true}
          // customActions={[
          //   {
          //     icon: <IoGlobe />,
          //     id: 'id' + message.type.id,
          //     loading: loadingStates['id' + message.type.id] || false,
          //     onClick: async () => {
          //       setLoadingForButton('id' + message.type.id, true)
          //       try {
          //         let result = await translateMessage(message.type.text)
          //         message.type.text =
          //           message.type.text +
          //           '\n' +
          //           '. . .' +
          //           '\n' +
          //           result.translatedText
          //         setStateTrigger(result.translatedText)
          //       } catch (e) {
          //         alert(
          //           'connection to translation engine failed, please try again'
          //         )
          //       } finally {
          //         setLoadingForButton('id' + message.type.id, false)
          //       }
          //     }
          //   }
          // ]}
          removeButton={false}
          replyButton={false}
          retracted={false}
          status={message.type.status}
          titleColor={message.type.titleColor}
          actionButtons={
            index === 0 || message.type.status === 'waiting'
              ? []
              : getCitationNumbers()
          }
          likes={message.likes}
          dislikes={message.dislikes}
        />
      </>
    )
  }

  return (
    <Wrapper>
      <Modal
        title='Citation'
        open={isModalOpen}
        onCancel={handleOk}
        closable={true}
        maskClosable={true}
        footer={null}
      >
        <p>{modalContent}</p>

        <Descriptions title='Metadata' bordered items={items} />
      </Modal>

      <FileDrawer
        fileUrl={urlFile}
        open={openDrawer}
        setOpenDrawer={setOpenDrawer}
        fileTitle={urlTitle}
        page={initialPage}
      />

      {isLoading && <Spin spinning={isLoading} tip={'Loading'} />}
      {!isLoading &&
        messageList.map((message, index) => buildMessageBox(message, index))}
      <div ref={messageEndRef} style={{ height: 20 }} />
    </Wrapper>
  )
}

export default MessageList