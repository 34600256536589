import styled from 'styled-components'

export const Wrapper = styled.div`
  
  height: calc(94vh - 90px);
  overflow-y: auto;
  white-space: normal;

  p {
    padding: 0;
    margin: 0 0 4px;
    white-space: pre-line;
  }

  ul {
    margin-bottom: 4px;
  }

  .rce-avatar-container.default {
    height: auto !important;
    width: 32px !important;
  }

  .rce-container-mbox {
    margin: 0 0 10px 0;
    width: 100%;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  .rce-mbox-title {
    color: #333 !important;
    font-weight: bold;
  }

  .rce-mbox-time {
    color: #333 !important;
  }

  .rce-mbox {
    background-color: transparent;
    color: #333;
    max-width: 97%;
    box-shadow: 1px 1px 1px 1px #fff3;
    width: 100%;
  }

  .rce-mbox-left-notch {
    fill: #ffffff !important;
  }

  .rce-mtlink-btn {
    background-color: transparent !important;
    flex-direction: column;
  }

  .rce-mtlink-btn-content {
    border: none;
  }

  .rce-mbox-right {
    background-color: #232326 !important;
    box-shadow: 1px 1px 1px 1px #232326 !important;
  }

  .rce-mtlink-title {
    white-space: pre-line !important;
  }

  .rce-mbox-right {
    .rce-mbox-title {
      color: #ffffff !important;
    }

    .rce-mbox-time {
      color: #ffffff !important;
    }

    .rce-mtlink-content .rce-mtlink-title {
      color: white !important;
    }

    .rce-mbox-right-notch {
      fill: #232326 !important;
    }
  }

  //  Style for meeting link message box
  .rce-mbox {
    .rce-mtlink-content {
      padding-bottom: 0;
      background-color: transparent !important;
    }
  }
  .rce-mtlink-item {
    user-select: text !important;
    table,
    tr,
    th,
    td {
      border: solid 1px #a0a0a0;
      padding: 5px 10px;
    }
  }
`

export const CitationButton = styled.div`
  color: #333;
  background-color: transparent !important;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 5px;
`
