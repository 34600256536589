import React, { FC, useContext, useState } from 'react'
import { Button, Form, Input, Space } from 'antd'
import { Wrapper } from './styled'
import { MdSend } from 'react-icons/md'
import { MessageOrigin } from 'services/utils'
import Filters, { FilterOption } from './Filters'
import questions from './prompt_library.json'
import FAQ from './FAQ'
import { useGetFiltersQuery } from '../../../../redux/apiSlice'
import { GroupContext } from '../../../../redux/contexts'

interface IProps {
  addMessage: (message: string, type: MessageOrigin) => void
  connectStream: (message: string, filters: FilterOption) => void
  showFaq?: boolean
  disabled?: boolean
}

const ChatInput: FC<IProps> = (props) => {
  const [form] = Form.useForm()
  const { addMessage, connectStream } = props
  const [filters, setFilters] = useState<FilterOption>({})

  const onFinish = async (data: any) => {
    try {
      const query = data['query']
      addMessage(query, MessageOrigin.SENDER)
      connectStream(query, filters)
      form.resetFields()
    } catch (e) {
      console.error('Failed to get text', e)
    }
  }
  const group = useContext(GroupContext)
  const { data: filterOptions } = useGetFiltersQuery(group.value, {
    skip: !group.value
  })
  const hasFilters = filterOptions && Object.keys(filterOptions).length > 0

  const query = Form.useWatch('query', form)
  const onFinishFailed = (errorInfo: any) => {
    console.log(`Error: ${errorInfo}`)
  }

  return (
    <Wrapper>
      {props.showFaq && query === '' && (
        <FAQ
          texts={questions.map((i) => i.question)}
          onClick={(question) => {
            form.setFieldValue('query', question)
          }}
        />
      )}

      <Form
        form={form}
        name='assitant'
        initialValues={{ query: '' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
        style={{ width: '100%!important' }}
      >
        <Space.Compact style={{ width: '100%' }}>
          <Form.Item
            name='query'
            style={{ width: '100%'}}
            required
          >
            <Input
              placeholder='Type here'
              disabled={props.disabled}
              size='large'
              suffix={
                hasFilters && (
                  <Filters filters={filters} setFilters={setFilters} />
                )
              }
            />
          </Form.Item>
          <Form.Item dependencies={['query']}>
            {() => (
              <Button
                disabled={query === '' || props.disabled}
                type='primary'
                size='large'
                icon={<MdSend />}
                htmlType='submit'
              />
            )}
          </Form.Item>
        </Space.Compact>
      </Form>
    </Wrapper>
  )
}

export default ChatInput
