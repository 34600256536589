import { Wrapper, Header, Title } from './styled'
import { Card, Row } from 'antd'

const Landing = () => {
  return (
    <Wrapper>
      <Header style={{ fontFamily: 'STC-Forward' }}>
        <Row>
          <>Powered By</>
          <>
            <>Avatar</>
            <>
              <>John Smith</>
              <>Market Intelligence</>
            </>
          </>
        </Row>
      </Header>

      <Title>STC Assistant</Title>

      <Row>
        <Card>Ask a Question</Card>
        <Card>Upload and Manage Data</Card>
      </Row>
    </Wrapper>
  )
}

export default Landing
