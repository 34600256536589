import React, { useContext, useEffect, useState } from 'react'
import { Layout as ALayout } from 'antd'
import { CustomContent, CustomSider, FooterImage } from './styled'

import { Outlet, useNavigate } from 'react-router-dom'
import useTheme from 'theme'
import SideContent from './components/SideContent'
import { GroupContext } from 'redux/contexts'
import { Group, IUser } from 'models'
import { useGetChatsHistoryQuery, useGetCurrentUserQuery } from 'redux/apiSlice'
import stcLogo from 'images/stc-logo.svg'
import bcgxLogo from 'images/01-bcgx-logo.png'
import { MessageListProvider } from '../Assistant/components/MessageList/MessageListProvider'

const { Footer } = ALayout

interface IProps {}

const Layout: React.FC<IProps> = ({}) => {
  // Refetch Chats after login
  useGetChatsHistoryQuery('', {
    refetchOnMountOrArgChange: true
  })

  const { COLORS } = useTheme()
  const [collapsed, setCollapsed] = useState(false)
  const group = useContext(GroupContext)
  const [selectedGroup, setSelectedGroup] = useState<Group>(group)
  const navigate = useNavigate()
  const { data: currentUser, isLoading, isError } = useGetCurrentUserQuery('')

  useEffect(() => {
    if (currentUser) {
      localStorage.setItem('currentUser', JSON.stringify(currentUser as IUser))
    }
    if (isError && !isLoading) {
      localStorage.removeItem('currentUser')
      localStorage.removeItem('AccessToken')
      navigate('/login')
    }
  }, [currentUser, isError, isLoading])

  // useEffect(() => {
  //   setMessageListData((temData) => {
  //     return { ...temData, messages: data || [] }
  //   })
  // }, [data])

  // const onHandleSelectMenuItem = (chatId: string) => {
  //   getMessagesFromChat(chatId)
  //   setMessageListData({ messages: data || [], chatId })
  // }

  // const onHandleNewChat = () => {
  //   setMessageListData({ messages: [], chatId: undefined })
  // }

  return (
    <>
      {isLoading ? (
        <span>Loading Profile</span>
      ) : (
        <ALayout style={{ height: '100vh', backgroundColor: '#white', fontFamily: 'STC-Forward' }}>
          <MessageListProvider>
            <CustomSider
              breakpoint='md'
              width='220'
              collapsedWidth='60'
              collapsible={false}
              collapsed={collapsed}
              backgroundColor={COLORS.primary}
              secondaryColor={COLORS.secondary}
              onCollapse={(value) => setCollapsed(value)}
            >
              <SideContent
                selectGroup={setSelectedGroup}
                currentUser={currentUser as IUser}
              />
            </CustomSider>

            <GroupContext.Provider value={selectedGroup}>
              <ALayout style={{ height: '100vh', overflow:'scroll', backgroundColor: 'white', fontFamily: 'STC-Forward' }}>
                <CustomContent>
                  <Outlet />
                </CustomContent>
                <Footer
                  style={{
                    height: 30,
                    padding: '1em',

                    backgroundColor: 'white',
                    fontSize: '10px',
                    color: '#62407C',
                    boxShadow: 'rgb(0 0 0 / 37%) 0px 2px 8px', 
                    fontFamily: 'STC-Forward',
                    position: 'fixed',
                    bottom: '0',
                    width: '100%'
                  }}
                >
                  <p style={{marginLeft:'34%'}}>
                  Copyright {' '}
                  <FooterImage
                    src={stcLogo}
                    alt={'STC LOGO'}
                    style={{
                      width: '25px',
                      height: 'auto',
                      position: 'relative',
                      bottom: '2px',
                      margin: '0 7px',
                      fontFamily: 'STC-Forward'
                    }}
                  />
                  ©2024
                  </p>
                </Footer>
              </ALayout>
            </GroupContext.Provider>
          </MessageListProvider>
        </ALayout>
      )}
    </>
  )
}

export default Layout