import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import {
  AddMessageBtn,
  AppName,
  CustomMenu,
  CustomMenuAction,
  CustomSelect,
  CustomSubMenuTitle,
  MenuItemTitle,
  MenuTitle,
  MenuWrapper,
  ScrollArea,
  SideFooter,
  TenantName,
  Wrapper
} from './styled'
import { Avatar, Button, Col, message, Popconfirm, Row, Tooltip, Menu } from 'antd'
import { FaFile } from 'react-icons/fa';
import { FaRegCommentDots, FaCommentDots } from 'react-icons/fa';
import { ClientContext, defaultGroup } from 'redux/contexts'
import { Group, IUser } from 'models'
import { useDeleteChatMutation, useGetTenantsQuery } from 'redux/apiSlice'
import {
  CloseCircleFilled,
  RightCircleOutlined,
  PlusCircleOutlined,
  PlusSquareFilled,
  UserOutlined
} from '@ant-design/icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Chat } from 'models/message'
import { RiChatNewFill } from 'react-icons/ri'
import { useMessageList } from '../../Assistant/components/MessageList/MessageListProvider'
import { BiChat, BiFileBlank, BiPlusCircle } from 'react-icons/bi';

const { SubMenu } = Menu;

interface IProps {
  selectGroup: (group: Group) => void
  currentUser: IUser
}

const SideContent: FC<IProps> = (props) => {
  const { selectGroup, currentUser } = props
  const profile = useContext(ClientContext)
  const { data: tenantList } = useGetTenantsQuery('', {
    refetchOnMountOrArgChange: true
  })
  const [deleteChatFn] = useDeleteChatMutation()
  const navigate = useNavigate()
  const location = useLocation()
  const [chatHistory, setChatHistory] = useState<Chat[]>([])
  const [selectedKey, setSelectedKey] = useState<string>('NONE')

  const menuItems = [
    { key: 'uploadFiles', icon: <BiFileBlank />, label: 'Upload Files' },
    {
      key: 'chatPage',
      label: 'Chat History',
      icon: <BiChat />,
      children: [
        { key: 'newChat', label: <>
        <CustomSubMenuTitle>New Chat</CustomSubMenuTitle>
        <CustomMenuAction><PlusCircleOutlined className="action-icon" /></CustomMenuAction></> },
        ...chatHistory?.map((chat, index) => ({key: chat.id, label: <>
        <CustomSubMenuTitle>{chat.title}</CustomSubMenuTitle>
        <Popconfirm
                placement="right"
                title="Are you sure to delete this chat?"
                description="Delete the chat"
                okText="Yes"
                cancelText="No"
                onConfirm={() => confirmDeleteChat(chat.id)}
              >
                <CustomMenuAction><CloseCircleFilled className="action-icon" /></CustomMenuAction>
        </Popconfirm>
        </>,}))
      ],
    },
  ]
  const handleChange = useCallback(
    (value: any) => {
      const tenant = tenantList?.find((t) => t.id === value)
      if (tenant) {
        selectGroup({ label: tenant.name, value: tenant.id })
      }
    },
    [selectGroup, tenantList]
  )

  const { chatList, newChat, selectConversation } = useMessageList()

  const handleLogout = () => {
    localStorage.removeItem('AccessToken')
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('currentUser')
    navigate('/login')
  }

  useEffect(() => {
    if (tenantList && tenantList.length > 0) {
      handleChange(tenantList[0].id)
    }
  }, [handleChange, tenantList])

  useEffect(() => {
    if (chatList && chatList.length > 0) {
      setChatHistory(chatList)
    }
  }, [chatList])

  const confirmDeleteChat = async (chatId: string) => {
    await deleteChatFn(chatId)
    message.success('Chat deleted successfully')
    const chats = [...chatHistory]
    const index = chats?.findIndex((chat) => chat.id === chatId)
    if (index !== undefined && index > -1) {
      chats?.splice(index, 1)
    }
    setChatHistory((tem) => [...chats])
  }

  const onCreateNewChat = () => {
    newChat()
    setSelectedKey('NONE')
  }

  const onHandleSelectItem = (key: string) => {
    if (key === 'uploadFiles') {
      console.log('uploadFiles', key)
      navigate('/settings/uploads');
    } else if (key === 'newChat') {
      navigate('/')
      onCreateNewChat()
    } else {
      console.log("elsing....", key)
      navigate('/')
      selectConversation(key)
      setSelectedKey(key)
    }
  }

  return (
    <Wrapper>
      <Row gutter={[6, 12]}>
      {currentUser && (
        <SideFooter>
          <span style={{marginTop: '19px'}} className='username'>
            {currentUser.firstname} {currentUser.lastname}
          </span>
          <Button style={{marginTop: '20px'}}
            type='text'
            icon={
              <RightCircleOutlined
                size={32}
                style={{
                  color: 'red',
                }}
              />
            }
            onClick={handleLogout}
          />
        </SideFooter>
      )}
        
        {/* <Col span={16}> */}
          {/* {tenantList && tenantList.length > 1 ? (
            <CustomSelect
              defaultValue={
                tenantList && tenantList?.length > 0
                  ? tenantList[0].id
                  : defaultGroup
              }
              style={{ width: '100%' }}
              placement='topLeft'
              onChange={handleChange}
              options={tenantList?.map((tenant) => ({
                value: tenant.id,
                label: tenant.name
              }))}
            />
          ) : (
            tenantList &&
            tenantList.length > 0 && (
              <TenantName>{tenantList[0].name}</TenantName>
            )
          )} */}
        {/* </Col> */}

        {/* <div>
          <div
            style={{
              border: '1px solid #A5A5A9',
              height: '100%',
              margin: '0 10px'
            }}
          />
        </div> */}

        {/* <Col>
          <Button
            type='text'
            icon={<FaRegCommentDots size={26} color='#FFFFFF' />}
            size='middle'
            style={{ bottom: '3px', color: '#FFF' }}
            onClick={() => navigate('/')}
          />
        </Col> */}

        {/* <Col>
          <Button
            type='text'
            icon={<FaFile size={26} color='#FFFFFF' />}
            size='middle'
            style={{ bottom: '3px' }}
            onClick={() => navigate('/settings/uploads')}
          />
        </Col> */}
      </Row>
      <div>
          <div
            style={{
              border: '0.7px solid rgb(255 255 255 /50%)',
              height: '100%',
              margin: '0 1px',
            }}
          />
        </div>
     
      {/* {location.pathname.includes('settings') ? (
        <MenuWrapper>
          <Menu
          mode="inline"
          selectedKeys={[selectedKey]}
          onClick={(e) => {
            onHandleSelectItem(e.key);
            if (e.key === 'chatPage') {
              navigate('/');
            }
          }}
          style={{
            backgroundColor: '#62407C',
            borderRadius: '5px',
            maxHeight: '400px',
            overflowY: 'auto',
            marginTop: '20px'
          }}
        >
          <Menu.Item
            key="chatPage"
            style={{
              backgroundColor: '#62407C',
              borderRadius: '5px',
              color: 'white',
              fontFamily: 'STC-Forward',
            }}
          >
            Chat Page
          </Menu.Item>
        </Menu>
          {/* <NavLink to={'/'}>Chat Page</NavLink> */}
        {/*</Wrapper>  {currentUser?.role === 'admin' && (
            <>
              <NavLink to={'/settings/users'}>Users</NavLink>
              <NavLink to={'/settings/groups'}>Settings</NavLink>
            </>
          )}
        </MenuWrapper>
      ) : (
      <MenuWrapper>

<Menu
    mode="inline"
    selectedKeys={[selectedKey]}
    onClick={(e) => {
      onHandleSelectItem(e.key);
      if (e.key === 'uploadFiles') {
         navigate('/settings/uploads');
      }
    }}
    style={{
      backgroundColor: '#62407C',
      borderRadius: '5px',
      overflowY: 'auto',
      marginTop: '20px'
    }}
  >
    <Menu.Item
      key="uploadFiles"
      style={{
        backgroundColor: '#62407C',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'STC-Forward',
      }}
    >
      Upload Files
    </Menu.Item>
  </Menu>

  {chatHistory && chatHistory?.length > 0 && (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
      onClick={(e) => {
        onHandleSelectItem(e.key);
      }}
      style={{
        backgroundColor: '#62407C',
        borderRadius: '5px',
        marginTop: '20px',
        overflowY: 'auto',
      }}
    >
      <SubMenu
        key="yourChats"
        title={
          <span style={{ color: 'white', fontFamily: 'STC-Forward' }}>
            Your Chats
          </span>
        }
        style={{
          backgroundColor: '#62407C',
          borderRadius: '5px',
          color: 'white'
        }}
        popupClassName="custom-submenu"
      >
        <Menu.Item key="newChat">
          <div style={{ fontFamily: 'STC-Forward' }}>
            <MenuItemTitle>New Chat</MenuItemTitle>
            <Tooltip placement="right" title={'New chat'} arrow={true}>
              <AddMessageBtn onClick={onCreateNewChat} type="link">
                <RiChatNewFill style={{  color: 'white' }} />
              </AddMessageBtn>
            </Tooltip>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                borderTop: '1px solid rgb(255 255 255 /30%)',
              }}
            />
          </div>
        </Menu.Item>

        {chatHistory?.map((chat) => (
          <Menu.Item key={chat.id}>
            <div style={{ fontFamily: 'STC-Forward', position: 'relative', display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flex: 1,
                }}
              >
                {chat.title}
              </div>
              <Popconfirm
                placement="right"
                title="Are you sure to delete this chat?"
                description="Delete the chat"
                okText="Yes"
                cancelText="No"
                onConfirm={() => confirmDeleteChat(chat.id)}
              >
                <CloseCircleFilled className="action-icon" />
              </Popconfirm>
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                borderTop: '1px solid rgb(255 255 255 /30%)',
              }}
            />
          </Menu.Item>
        ))}
      </SubMenu>
    </Menu>
  )}




<style>
      {`
        .custom-submenu .ant-menu-submenu-arrow {
          color: #390065;
        }
        .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active {
          height: 46px;
         }
        .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
          height: 770px;
        }
        li.ant-menu-item.ant-menu-item-only-child {
          padding-left: 20px !important;
      }
      li.ant-menu-item {
        padding-left: 20px !important;
    }
      `}
    </style>
      </MenuWrapper>
      )} */}
      <CustomMenu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        onClick={(e) => {
          onHandleSelectItem(e.key);
        }}
        // inlineCollapsed={collapsed}
        items={menuItems}
      />
    </Wrapper>
  )
}

export default SideContent