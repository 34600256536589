import React, { FC, useContext, useEffect, useRef, useState, CSSProperties } from 'react'
import MessageList from './components/MessageList/MessageList'
import ChatInput from './components/Input/ChatInput'
import { Alert, Col, notification, Row } from 'antd'
import { generateMessage, MessageOrigin } from 'services/utils'
import { config } from 'services/config'
import stcLogo from 'images/stc-logo.svg'
import {
  IChatListData,
  Message,
  RawMessage,
  SourceDocument
} from 'models/message'
import LZString from 'lz-string'
import { GroupContext } from 'redux/contexts'
import { FilterOption } from './components/Input/Filters'
import { useMessageList } from './components/MessageList/MessageListProvider'

const MAX_CONTEXT_LENGTH = 7000

interface IProps {}


const Assistant: FC<IProps> = (props) => {
  const [messageList, setMessageList] = useState<Message[]>([])
  const messageEndRef = useRef<HTMLDivElement>(null)
  const group = useContext(GroupContext)
  const {
    current: messageListData,
    newChat,
    isLoading,
    selectConversation
  } = useMessageList()
  const [evtSource, setEvtSource] = useState<EventSource | null>(null)

  useEffect(() => {
    setMessageList([]) // Reset messageList state when messageListData changes
    if (evtSource) {
      evtSource.close() // Cancel the connectStream function when messageListData changes
    }
  }, [messageListData])

  useEffect(() => {
    scrollToBottom()
  }, [messageList.length])

  const sortMessages = (messages: RawMessage[]) => {
    const tempMessages = [...messages]
    return tempMessages.sort((a, b) => {
      if (new Date(a.created_at) < new Date(b.created_at)) {
        return -1
      }
      if (new Date(a.created_at) > new Date(b.created_at)) {
        return 1
      }
      return 0
    })
  }

  useEffect(() => {
    const initMessage = generateMessage(
      'Hi, I am a virtual assistant. How can I help you?',
      MessageOrigin.RECEIVER,
      'received',
      true
    )
    setMessageList([initMessage])
    const messages = sortMessages(messageListData?.messages || [])
    for (let m of messages) {
      if (m.author !== 'LLAMA2') {
        addMessage(
          m.content,
          MessageOrigin.SENDER,
          'sent',
          new Date(m.created_at),
          m.id,
          [],
          m.like,
          m.dislike
        )
      } else {
        addMessage(
          m.content,
          MessageOrigin.RECEIVER,
          'received', 
          new Date(m.created_at),
          m.id,
          m.meta_data?.sources || [],
          m.like,
          m.dislike
        )
      }
    }
  }, [messageListData])

  const addMessage = (
    rawMessage: string,
    type: MessageOrigin,
    status: 'waiting' | 'sent' | 'received' | 'read' = 'received',
    createdAt: Date = new Date(),
    id: string = String(Math.random()),
    sources: SourceDocument[] = [],
    likes: number = 0,
    dislikes: number = 0,
    
  ) => {
    const message = generateMessage(
      rawMessage,
      type,
      status,
      false,
      createdAt,
      id,
      sources,
      likes,
      dislikes
    )
    setMessageList((prevList) => [...prevList, message])
    scrollToBottom();
  }

  const [api, contextHolder] = notification.useNotification()

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const connectStream = (question: string, filters: FilterOption) => {
    const chat_id =
      messageListData && messageListData.chatId
       ? messageListData.chatId
        : messageList[0].chat_id
    let query_params: IChatListData = {
      collection: group.value,
      question: question,
      filters,
      personality: 'STC',
      chat_id: chat_id,
      history: []
    }

    let compressedParams = ''

    for (const message of messageList) {
      compressedParams = LZString.compressToEncodedURIComponent(
        JSON.stringify(query_params)
      )
      if (compressedParams.length < MAX_CONTEXT_LENGTH) {
        query_params.history.push({
          content: message.type.text,
          isBot: message.type.position === 'left',
          isURL: false,
          isTimestamp: false,
          sources: message.sourcesDocuments,
          chat_id: chat_id
        })
      } else {
        break
      }
    }

    compressedParams = LZString.compressToEncodedURIComponent(
      JSON.stringify(query_params)
    )

    if (messageList.length === 1 && chat_id) {
      newChat({
        id: chat_id,
        title: question,
        created_at: new Date().toString(),
        user_id: 'System'
      })
    }

    addMessage(
      'Please wait while I find information to assist you',
      MessageOrigin.RECEIVER,
      'waiting'
    )

    const token = localStorage.getItem('AccessToken')
    const url = `${config.BASE_URL}chatbot/query?json_obj=${compressedParams}&token=${token}`

    const evtSource = new EventSource(encodeURI(url))
    setEvtSource(evtSource) // Store the EventSource instance
    let content = ''

    try {
      evtSource.addEventListener('new_message', function (event) {
        const data = JSON.parse(event.data)
        content = content + data.data
        setMessageList((prevMessages) => [
          ...prevMessages.slice(0, -1),
          generateMessage(
            content,
            MessageOrigin.RECEIVER,
            'received',
            false,
            new Date(),
            data.message_id,
            [],
            data.like,
            data.dislike
          )
        ])
      })

      evtSource.addEventListener('error', function (event) {
        api.error({ message: 'Error sending message' })
        evtSource.close()
      })
      evtSource.addEventListener('end_event', function (event) {
        const sourceDocuments = JSON.parse(event.data).sources
        const chat_id = JSON.parse(event.data).chat_id

        setMessageList((prevMessages) => {
          const updatedMessages = [...prevMessages]
          const lastMessageIndex = updatedMessages.length - 1
          const lastMessage = updatedMessages[lastMessageIndex]
          lastMessage.sourcesDocuments = sourceDocuments
          lastMessage.chat_id = chat_id
          return updatedMessages
        })

        evtSource.close()
      })
    } catch (error) {
      console.error('Error sending message:', error)
      evtSource.close()
    }
  }

  // const builtAlertMessage = () => {
  //   if (group.value === 'none') {
  //     return (
  //       <div
  //         style={{
  //           borderRadius: '5px',
  //           backgroundColor: '#62407C',
  //           color: 'white',
  //         }}
  //       >
  //         Please select a knowledge base to connect to
  //       </div>
  //     );
  //   }
  //   return (
  //     <div
  //       style={{
  //         borderRadius: '5px',
  //         backgroundColor: '#62407C',
  //         color: 'white',
  //       }}
  //     >
  //       Currently you are connected to a {group.label} knowledge base
  //     </div>
  //   );
  // };

  const containerStyle: React.CSSProperties = {

  };
  
  const messageListContainerStyle: React.CSSProperties = {

  };
  
  const messageListStyle: React.CSSProperties = {
    fontFamily: 'STC-Forward',
   
  };
  
  return (
    <>
          <style>{`

        .headerStyle {
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          box-shadow: rgb(0 0 0 / 37%) 0px 2px 8px;
        }

        .headerLogo {
          height: 50px; /* Adjust size as needed */
        }

        .headerTitle {
          flex-grow: 1;
          text-align: center;
          font-weight: bold;
          font-size: 24px; /* Adjust size as needed */
        }

      `}</style>
       {/* Header */}
       <div className="headerStyle">
       <div style={{fontFamily: 'STC-Forward', color: '#4f008c'}}className="headerTitle">Ask Your Knowledge Base</div>
       <img
            src={stcLogo}
            alt="STC Logo"
            className="headerLogo"
            style={{ height: '30px', width: 'auto' }} // Add inline styles here
          /> 
        <div style={{fontSize:'29px', alignSelf:'center', justifySelf:'center', color:'#4f008c', fontWeight:'bolder', marginLeft:'5px'}}>Brain</div>
      </div>
      <Row justify='center'>
      {contextHolder}
      <Col xs={24} sm={24} md={22} lg={22} xl={22}>
        {/* <Alert
          style={{ marginTop: 15, fontFamily: 'STC-Forward', backgroundColor: '#62407C', borderRadius: '40px', fontSize: '17px', borderColor: 'rgb(98, 64, 124)' }}
          message={builtAlertMessage()}
          type={group.value === 'none' ? 'error' : 'success'}
        /> */}
        <div style={containerStyle}>
          <div style={messageListContainerStyle}>
            <div style={messageListStyle}>
              <MessageList messageList={messageList} isLoading={isLoading} />
            </div>
          </div>
          <div style={{ fontFamily: 'STC-Forward' }}>
            <div style={{marginTop:'50px'}}>
              <ChatInput
                addMessage={addMessage}
                connectStream={connectStream}
                showFaq={messageList.length === 1}
              />
            </div>
          </div>
          <div ref={messageEndRef} />
        </div>
      </Col>
    </Row>
    </>
  );
}

export default Assistant
