import React, { useEffect, useState } from 'react'
import { Alert, Form, Input, Row, Layout } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGetAPIStatusQuery, useLoginMutation } from 'redux/apiSlice'
import stcLogoWhite from 'images/stc-logo-white.png'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import "App.css"
import stcLogo from 'images/stc-logo.svg'
import {
  Wrapper,
  FormWrapper,
  FromTitle,
  FooterImage,
  FormItem,
  ResponsiveButton
} from './styled'

const { Footer } = Layout

const Login: React.FC = (props) => {
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState(false)

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated')
    const accessToken = localStorage.getItem('AccessToken')
    if (isAuthenticated && accessToken) {
      navigate('/')
    }
  }, [navigate])

  const [loginFn, { isLoading: isLoginLoading }] = useLoginMutation()
  const { isError: hasLlmError } = useGetAPIStatusQuery('', {
    refetchOnMountOrArgChange: true
  })

  const onFinish = async (values: any) => {
    try {
      console.log('Success:', values)
      const accessData = await loginFn({
        username: values.username,
        password: values.password
      }).unwrap()
      if (accessData) {
        localStorage.setItem('AccessToken', accessData.access_token as string)
        localStorage.setItem('isAuthenticated', JSON.stringify(true))
        navigate('/')
      }
    } catch (e) {
      console.log('Failed:', e)
      setLoginError(true)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div>
      <style>{`

      .headerStyle {
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;
        display: flex;
        padding: 10px 20px;
        box-shadow: rgb(0 0 0 / 37%) 0px 2px 8px;
        justify-content: center;
      }

      .headerLogo {
        height: 50px; /* Adjust size as needed */
      }

      .headerTitle {
        
        text-align: center;
        font-weight: bold;
        font-size: 24px; /* Adjust size as needed */
      }

      `}</style>
      {/* Header */}
      <div className="headerStyle">
       <div style={{fontFamily: 'STC-Forward', color: '#4f008c'}}className="headerTitle">Ask Your Knowledge Base</div>
       <div style={{display:'flex', position:'fixed', top:'5px', right:'5px'}}>
          <img
            src={stcLogo}
            alt="STC Logo"
            className="headerLogo"
            style={{ height: '30px', width: 'auto' }} // Add inline styles here
          /> 
          <div style={{fontSize:'29px', color:'#4f008c', fontWeight:'bolder', marginLeft:'5px'}}>Brain</div>
        </div>
      </div>
      <FormWrapper bordered={false}>
        <FromTitle>Log in</FromTitle>
        <hr className="form-separator" />
        <style>
          {`
            .form-separator {
              border: none;
              border-bottom: 1px solid #ccc;
              margin-bottom: 50px;
              width: 700px;
              margin-left: auto;
              margin-right: auto;

              @media (max-width: 1200px) {
                width: 60vw; 
              }
              @media (max-width: 1000px) {
                width: 50vw;
              }
              @media (max-width: 800px) {
                width: 40vw;
              }
              @media (max-width: 600px) {
                width: 30vw;
              }
              @media (max-width: 400px) {
                width: 80%;
              }
            }

      
          `}
        </style>

        {hasLlmError && (
          <Alert
            style={{ marginBottom: '30px' }}
            className="stc-forward"
            message='Warning'
            type='warning'
            showIcon
            closable
            description='This application is temporarily down, It will be running from 8:00 to 18:00 Riyadh time.'
          />
        )}

        <Form
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <FormItem>
            <Form.Item
              name='username'
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input
                placeholder='Username'
                className="stc-forward"
                prefix={<UserOutlined className="input-prefix-icon" />}
              />
            </Form.Item>
          </FormItem>

          <FormItem>
            <Form.Item
              name='password'
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                placeholder='Password'
                className="stc-forward"
                prefix={<LockOutlined className="input-prefix-icon" />}
              />
            </Form.Item>
          </FormItem>

          <style>
            {`
              .input-prefix-icon {
                display: flex;
                align-items: center;
                border-right: 1px solid #ccc;
                padding-right: 8px;
                margin-right: 8px;
              }
              @media (max-width: 1200px) {
                width: 60vw; 
              }
              @media (max-width: 1000px) {
                width: 50vw;
              }
              @media (max-width: 800px) {
                width: 40vw;
              }
              @media (max-width: 600px) {
                width: 30vw;
              }
              @media (max-width: 400px) {
                width: 80%;
              }
            }
            `}
          </style>

          <FormItem>
            <Form.Item wrapperCol={{ span: 24 }}>
              <ResponsiveButton
                type='primary'
                htmlType='submit'
                loading={isLoginLoading}
                className="stc-forward"
              >
                Login
              </ResponsiveButton>
            </Form.Item>
          </FormItem>

          {isLoginLoading && <div>Loading...</div>}
          {loginError && (
            <div style={{ color: 'red' }}>Incorrect username or password</div>
          )}
        </Form>
      </FormWrapper>

      <Footer
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '100%',
          height: '50px',
          padding: '1em',
          textAlign: 'center',
          backgroundColor: 'rgb(72, 47, 130)',
          fontSize: '15px',
          color: 'rgb(255 255 255)',
          fontFamily: 'STC-Forward'
        }}
      >
        Copyright {' '}
        <FooterImage
          src={stcLogoWhite}
          alt={'STC LOGO'}
          style={{
            width: '50px',
            height: 'auto',
            position: 'relative',
            bottom: '2px',
          }}
        />
        ©2024
      </Footer>
    </div>
  )
}

export default Login
