import styled from 'styled-components';
import { Card, Button, Input } from 'antd';

export const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormWrapper = styled(Card)`
  max-width: 950px;
  margin: auto;
  margin-top: 50px;
  padding: 25px 70px;
  border-radius: 50px;
  background-color: #ffffff;
  border-color: #482f82 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 38%) !important;
  border: 1px solid rgb(216 216 216);

  @media (max-width: 800px) {
    padding: 25px 30px;
  }
`;

export const FromTitle = styled.h1`
  font-size: 3em !important;
  font-weight: 700;
  text-align: center;
  font-family: STC-Forward;
  color: #390065;
`;

export const FooterImage = styled.img`
  width: 80px;
  height: auto;
`;

export const FormItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  & > div {
    width: 100%;
  }
`;

export const ResponsiveButton = styled(Button)`
  background-color: #482f82;
  width: 100%;
  font-size: 1em;
  display: block;
  margin: 0 auto;

  @media (min-width: 400px) {
    max-width: 400px;
  }

  @media (min-width: 600px) {
    max-width: 600px;
  }

  @media (min-width: 800px) {
    max-width: 800px;
  }

  @media (min-width: 1000px) {
    max-width: 1000px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;
