import React, { useContext, useMemo, useState, useEffect } from 'react';
import stcLogo from 'images/stc-logo.svg';
import { InboxOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  message,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Upload as AUpload,
  UploadProps
} from 'antd';
import { config } from 'services/config';
import { GroupContext } from 'redux/contexts';
import { useDeleteFileMutation, useGetFilesQuery } from 'redux/apiSlice';
import FileDrawer from '../Assistant/components/FileDrawer/FileDrawer';
import styled from 'styled-components';
import { UploadFile } from 'antd/es/upload/interface';
import MetadataDrawer from './MetadataDrawer';
import { RFile } from '../../models/file';
import { BiTrash } from 'react-icons/bi';

const Wrapper = styled.div`
  background: white;
`;

const UploadView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const mimeTypeToExtension: { [key: string]: string } = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word Document (.docx)',
  'application/msword': 'Word Document (.doc)',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'Excel Spreadsheet (.xlsx)',
  'application/vnd.ms-excel': 'Excel Spreadsheet (.xls)',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint Presentation (.pptx)',
  'application/vnd.ms-powerpoint': 'PowerPoint Presentation (.ppt)',
  'application/pdf': 'PDF Document',
  // 'image/jpeg': 'JPEG Image',
  // 'image/png': 'PNG Image',
  // 'image/gif': 'GIF Image',
  // 'image/svg+xml': 'SVG Image',
  // 'image/webp': 'WEBP Image',
  // 'image/bmp': 'Bitmap Image',
  // 'image/tiff': 'TIFF Image',
  // 'text/plain': 'Text File',
  'text/csv': 'CSV File',
  // 'text/html': 'HTML Document',
  // 'text/css': 'CSS File',
  // 'text/javascript': 'JavaScript File',
  // 'application/json': 'JSON File',
  // 'application/xml': 'XML Document',
  // 'application/zip': 'ZIP Archive',
  // 'application/x-rar-compressed': 'RAR Archive',
  // 'application/x-7z-compressed': '7z Archive',
  // 'application/x-tar': 'TAR Archive',
  // 'audio/mpeg': 'MP3 Audio',
  // 'audio/wav': 'WAV Audio',
  // 'audio/ogg': 'OGG Audio',
  // 'video/mp4': 'MP4 Video',
  // 'video/mpeg': 'MPEG Video',
  // 'video/ogg': 'OGG Video',
  // 'video/webm': 'WEBM Video',
  // 'application/octet-stream': 'Binary File'
  // ... add more types as needed
}


const Upload: React.FC = () => {
  const { Dragger } = AUpload;
  const group = useContext(GroupContext);
  const { data: filesData, error, refetch } = useGetFilesQuery(group.value!, { skip: group.value === '' }); // TODO: get tenant id=
  const [deleteFile] = useDeleteFileMutation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [urlFile, setUrlFile] = useState('');
  const [urlTitle, setUrlTile] = useState('');
  const accessToken = localStorage.getItem('AccessToken');
  const [uploadingFiles, setUploadingFiles] = useState<UploadFile[]>([]);
  const [errorMessages, setErrorMessages] = useState<Set<string>>(new Set());
  const [api, contextHolder] = notification.useNotification();
  const [uploadingCount, setUploadingCount] = useState(0);

  useEffect(() => {
    if (uploadingCount === 0 && errorMessages.size > 0) {
      message.error(Array.from(errorMessages).join('\n'));
      setErrorMessages(new Set());
    }
  }, [uploadingCount, errorMessages]);

  const processFiles = async (fileList: UploadFile[]) => {
    const errors = new Set<string>();
    const validFiles = fileList.filter((file) => {
      if (!file.size || file.size / 1024 / 1024 >= config.MAX_FILE_SIZE) {
        errors.add(`File size must be smaller than ${config.MAX_FILE_SIZE}MB.`);
        return false;
      }

      const totalFiles = uploadingFiles.length + fileList.length;
      if (totalFiles > config.MAX_FILE_COUNT) {
        errors.add(`You can only upload up to ${config.MAX_FILE_COUNT} files.`);
        return false;
      }

      if (!file.type || !mimeTypeToExtension[file.type]) {
        errors.add('You can only upload pdf, doc, docx, xls, xlsx, ppt, pptx and csv files.');
        return false;
      }

      return true;
    });

    if (errors.size > 0) {
      setErrorMessages(errors);
      return false;
    }

    setUploadingFiles([...uploadingFiles, ...validFiles]);
    return true;
  };

  const props: UploadProps = {
    name: 'files',
    multiple: true,
    fileList: uploadingFiles,
    accept: '.pdf,.docx,.pptx,.xlsx,.xls,.csv',
    data: { identifier: group.value },
    action: `${config.BASE_URL}knowledgebase/ingest`,
    maxCount: config.MAX_FILE_COUNT,
    headers: { Authorization: accessToken ? `Bearer ${accessToken}` : '' },

    beforeUpload(file, fileList) {
      return processFiles(fileList);
    },

    customRequest({ file, onSuccess, onError, onProgress }) {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${config.BASE_URL}knowledgebase/ingest`);
      xhr.setRequestHeader('Authorization', accessToken ? `Bearer ${accessToken}` : '');
      xhr.onload = () => {
        if (xhr.status === 200) {
          onSuccess && onSuccess(xhr.response);
        } else {
          onError && onError(xhr.response);
        }
      };
      xhr.onerror = () => {
        onError && onError(xhr.response);
      };
      xhr.upload.onprogress = (event) => {
        if (event.total > 0) {
          const percent = (event.loaded / event.total) * 100;
          onProgress && onProgress({ ...event, percent });
        }
      };
      const formData = new FormData();
      formData.append('files', file);
      formData.append('identifier', group.value);
      xhr.send(formData);
    },

    onChange(info) {
      const { status } = info.file;
      if (status === 'uploading') {
        setUploadingFiles([...info.fileList]);
      }
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setUploadingFiles(uploadingFiles.filter((file) => file.uid !== info.file.uid));
        refetch();
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        setUploadingFiles(uploadingFiles.filter((file) => file.uid !== info.file.uid));
        refetch();
        const errorDetails = info.file.response ? info.file.response.message || '' : '';
        setErrorMessages((prev) => new Set([...Array.from(prev), `${info.file.name} file upload failed. ${errorDetails}`]));
      }
    },

    onDrop(e) {
      const newErrorMessages: Set<string> = new Set();
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files.item(i);
        if (file) {
          if (!file.type || !mimeTypeToExtension[file.type]) {
            newErrorMessages.add('You can only upload pdf, doc, docx, xls, xlsx, ppt, pptx and csv files.');
          }
        }
      }
      if (newErrorMessages.size > 0) {
        setErrorMessages((prev) => new Set([...Array.from(prev), ...Array.from(newErrorMessages)]));
        message.error(Array.from(newErrorMessages).join('\n'));
      }
    }
  };

  const onSelectFile = (url: string, title: string) => {
    setUrlFile(url);
    setUrlTile(title);
    setOpenDrawer(true);
  };

  const parseExtFromFile = (type: string) => {
    const extension = mimeTypeToExtension[type];
    return extension !== undefined ? extension : type.split('/').pop() || 'unknown';
  };

  const columns = useMemo(
    () => [
      // {
      //   title: 'ID',
      //   dataIndex: 'id',
      //   key: 'id'
      // },
      {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        render: (title: string, row: RFile) => (
          <Button
            type={'link'}
            onClick={() => {
              onSelectFile(
                `https://${config.OSS_BUCKET_NAME}.${config.OSS_ENDPOINT}/${config.OSS_MAIN_DIR}/${row.tenant_id}/${title}`,
                //https://knowledge-management-bucket.oss-me-central-1.aliyuncs.com/knowledge-base/8da90f0d-9c82-4b78-ad0f-3077a3155ae3/2020-trends-to-watch-higher-education-technology-pdf%20(1).pdf
                title
              );
            }}
          >
            {title}
          </Button>
        )
      },
      // {
      //   title: 'Uploaded date',
      //   dataIndex: 'created_at',
      //   key: 'created_at',
      //   render: (text: string) => {
      //     return <span>{moment(text).fromNow()}</span>
      //   }
      // },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (type: string) => <Tag color='magenta'>{parseExtFromFile(type)}</Tag>
      },
      {
        title: 'Actions',
        dataIndex: 'meta_data',
        key: 'meta_data',
        render: (_metadata: any, row: RFile) => (
          <Space align={'center'}>
            <MetadataDrawer file={row} />
            <Tooltip title={'Delete'}>
              <Popconfirm
                title={'Are you sure?'}
                okText={'Yes'}
                cancelText={'No'}
                onConfirm={() => {
                  deleteFile({ tenant_id: row.tenant_id, file_id: row.id })
                    .unwrap()
                    .catch(() =>
                      api.error({
                        message: 'Failed',
                        description: 'Error removing file'
                      })
                    );
                }}
              >
                <Button icon={<BiTrash />} size={'small'}></Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }
    ],
    [deleteFile, api]
  );
  // {
  //     title: 'Embedding Reference',
  //     dataIndex: 'embedding_reference',
  //     key: 'embedding_reference',
  // },
  // {
  //     title: 'Published year',
  //     dataIndex: 'published_year',
  //     key: 'published_year',
  // },
  // {
  //     title: 'Hash',
  //     dataIndex: 'hash',
  //     key: 'hash',
  // },

    return (
    <>
        <style>{`

      .headerStyle {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        box-shadow: rgb(0 0 0 / 37%) 0px 2px 8px;
      }

      .headerLogo {
        height: 50px; /* Adjust size as needed */
      }

      .headerTitle {
        flex-grow: 1;
        text-align: center;
        font-weight: bold;
        font-size: 24px; /* Adjust size as needed */
      }

    `}</style>
    {/* Header */}
    <div className="headerStyle">
    <div style={{fontFamily: 'STC-Forward', color: '#4f008c'}}className="headerTitle">Ask Your Knowledge Base</div>
    <img
          src={stcLogo}
          alt="STC Logo"
          className="headerLogo"
          style={{ height: '30px', width: 'auto' }} // Add inline styles here
        />
    <div style={{fontSize:'29px', alignSelf:'center', justifySelf:'center', color:'#4f008c', fontWeight:'bolder', marginLeft:'5px'}}>Brain</div>
    </div>
    <Wrapper>
      {contextHolder}
      <UploadView style={{width:"100%"}}>
      {/* <Col xs={24} sm={24} md={22} lg={22} xl={22}> */}
        <Dragger style={{width:"90em"}}{...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text' style={{ fontFamily: 'STC-Forward' }}>
          Click or drag to upload files
          </p>
          <p className='ant-upload-hint' style={{ fontFamily: 'STC-Forward' }}>Supported file types: PDF, CSV, Word Document, Excel Spreadsheet, PowerPoint Presentation.</p>
          <p className='ant-upload-hint' style={{ fontFamily: 'STC-Forward' }}>You can upload {config.MAX_FILE_COUNT} files at a time</p>
          <p className='ant-upload-hint' style={{ fontFamily: 'STC-Forward' }}>Maximum file size: {config.MAX_FILE_SIZE}MB</p>

        </Dragger>
      {/* </Col> */}
      </UploadView>
      <Row
        justify='center'
      >
        {error && (
          <Alert
            message='Error'
            description={`There is something wrong trying to fetch the ${group.label} files`}
            type='error'
            showIcon
            closable={true}
          />
        )}

        <Col
          xs={24}
          sm={24}
          md={22}
          lg={22}
          xl={22}
        >
          <FileDrawer
            fileUrl={urlFile}
            page={1}
            setOpenDrawer={setOpenDrawer}
            open={openDrawer}
            fileTitle={urlTitle}
          />
          <Table pagination={{pageSize:5}}style={{height:'230px'}} dataSource={filesData} columns={columns} />
        </Col>
      </Row>


    </Wrapper>
    </>
  )
}

export default Upload
