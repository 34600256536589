import styled from 'styled-components'
import { Button, Menu, Select } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const CustomSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    font-size: 1.2em !important;
    color: #ffffff !important;
  }

  .ant-select-arrow {
    color: #ffffff !important;
  }

  .ant-select-selection-item {
    color: #ffffff !important;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100vh;
`

export const AppName = styled.div`
  color: var(--text-text-tertiary, #ffffff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 40px 0;
`

export const SideFooter = styled.div`
  padding: 10px;
  color: #ffffff;
  font-size: 1.3em;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
 
`

export const TenantName = styled.div`
  color: #ffffff;
  font-size: 17px;
  margin-top: 12px;
  margin-left: 20px;
  font-family: 'STC-Forward';
`

export const MenuTitle = styled.div`
  color: #ffffff;
  margin-left: 10px;
  opacity: 0.8;
  margin-bottom: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const MenuWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  a {
    color: #ffffff;
    padding: 12px 24px;
    margin: 4px;
    &:hover,
    &.active {
      background-color: #1b052d;
      border-radius: 10px;
    }
    &.active {
      color: rgb(255 255 255);
      font-weight: bold;
    }
  }
`

export const ScrollArea = styled(PerfectScrollbar)`
  .ps__rail-y:hover {
    background-color: transparent;
  }
  flex: 1;
  overflow: scroll;
`
export const CustomMenu = styled(Menu)`
  background: transparent;
  color: #ffffff;
  font-family: 'STC-Forward';
  overflow: scroll;
  .ant-menu-item:hover {
    background-color: #623f7c !important;
    color: #ffffff !important;
  }
  .ant-menu-submenu-title {
    color: #ffffff !important;
  }

  .ant-menu-item-selected {
    background-color: #623f7c !important;
    color: #ffffff !important;
  }

  .ant-menu-submenu-open {
    overflow: scroll;
  }
  .ant-menu-item {
    display: flex;
    flex-direction: row;
    color: #ffffff !important;
  }
  .ant-menu-title-content {
    color: #ffffff !important;
    display: flex;
    flex-direction: row;
  }
  .ant-menu-submenu .ant-menu-item {
    padding-left:20px !important;
    color: #ffffff !important;
  }

  }
`
export const CustomMenuAction = styled.div `
  padding-right: 5px;
  :hover {
    color: #ff375e;
  }
  
`
export const CustomSubMenuTitle = styled.div `
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 150px
`

export const MenuItemTitle = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;
`

export const AddMessageBtn = styled(Button)`
  float: right;
  font-size: 1.6em;
  color: #390065;
`
